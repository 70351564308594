<template>
  <div class="mb-100">
    <div class="divider-full"></div>
    <div class="container-fluid section-head">
      <div class="container-standar container-res-1440">
        <h1 class="pt-6 pb-6 text-white font-weight-6 font-24 d-inline-block">Visa</h1>
      </div>
    </div>
    <div class="cotainer-standar container-res-1440 text-center">
      <div class="container-standar mt-10 mb-10 pad-20">
        <div class="row">
          <div class="col-lg-7 pad-20 berita-content card shadow">
            <div role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    href="#"
                    v-b-toggle.accordion-1
                    variant="default"
                    class="text-primary w-100 text-left font-weight-6 font-18"
                  >
                    Deskripsi Layanan
                    <i class="float-right fa fa-angle-down"></i>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body class="text-left">
                    <b-card-text>
         
                        <div  class="clear"  v-if="dataSection !== null">
                        <h3>{{ dataSection.keterangan }}</h3>
                         </div>
                  
                     <div v-else>
                        <h3>Silahkan Pilih Visa Anda</h3>
                     </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    href="#"
                    v-b-toggle.accordion-2
                    variant="default"
                    class="text-primary text-left font-weight-6 font-18"
                  >
                    <i class="float-right fa fa-angle-down"></i> Syarat dan ketentuan
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body class="text-left">
                    <b-card-text>
                        <div  class="clear"  v-if="dataSection !== null">
                        <h3>{{ dataSection.syarat_ketentuan }}</h3>
                         </div>
                  
                     <div v-else>
                        <h3>Silahkan Pilih Visa Anda</h3>
                     </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <div class="col-lg-5">
            <div v-if="!LoginCheck">
              <div class="card shadow text-left pad-10">
                <span
                  class="d-inline-block bor-bot-1 width-100 pt-2 pb-2 font-18 font-weight-7 text-primary"
                >Form Pengisian Visa</span>
                <!-- <div class="col-lg-6"> -->
                <div class="form-group mt-3">
                  <label
                    for="destination"
                    class="text-primary font-12 font-weight-6"
                  >Pilih Layanan Visa</label>
                  <v-select
                    class="caret-icon"
                    :options="sortSelect"
                    label="name"
                    v-model="Visa_pick"
                    :value="sortSelect.name"
                    v-validate="'required'"
                    name="LayananVisa"
                    placeholder="Pilih Layanan Visa"
                  ></v-select>
                  <span class="eror text-danger font-10">{{errors.first('LayananVisa')}}</span>
                </div>
                <div class="form-group">
                  <label
                    for="anyDes"
                    class="text-primary font-12 font-weight-6"
                  >Tanggal Keberangkatan</label>
                  <div class="icon-date">
                    <flat-pickr
                      v-model="VisaDate"
                      :config="config"
                      class="input-same-select"
                      placeholder="Select date"
                      name="dateVisa"
                      v-validate="'required'"
                    ></flat-pickr>
                    <span class="eror text-danger font-10">{{errors.first('dateVisa')}}</span>
                  </div>
                </div>
                <div class="group-field">
                  <label for="anyDes" class="text-primary font-12 font-weight-6">Jumlah Visa</label>
                  <div class="input-group">
                    <input
                      type="number"
                      name="JumlahVisa"
                      class="form-control"
                      v-model="PaxVisa"
                      placeholder="Input Jumlah Pax"
                      v-validate="'max_value:100||min_value:1'"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2">Pax</span>
                    </div>
                  </div>
                  <span class="eror text-danger font-10">{{errors.first('JumlahVisa')}}</span>
                  <span
                    class="d-block w-100 text-danger font-12"
                  >*Batas pemesanan visa min 1 visa, max 100 visa</span>
                </div>
                <div class="form-group d-inline-block mt-4 pad-10">
                  <span class="float-left">Harga Visa/Pax</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>IDR.</span>
                    {{ changePricePax(perPax) | numeralFormat('0,0[.]00') }}
                  </span>
                </div>
                <div class="form-group bg-primary-thin pt-4 pb-4 pad-10 d-inline-block">
                  <span class="float-left">Total Harga</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>IDR.</span>
                    {{ totalPax | numeralFormat('0,0[.]00') }}
                  </span>
                </div>
                <div class="form-group pt-4 pb-4 pad-10 d-inline-block">
                  <span
                    @click="Validation()"
                    class="d-block w-70 bg-warning text-center pad-15 border-radius-5 cursor-pointer text-white"
                  >Selanjutnya</span>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="card shadow text-left pad-10">
                <span
                  class="d-inline-block bor-bot-1 width-100 pt-2 pb-2 font-18 font-weight-7 text-primary"
                >Review Pemesanan</span>
                <div class="form-group d-inline-block mt-4">
                  <span class="float-left">Nama Pemesan</span>
                  <span class="float-right font-weight-6 text-primary">{{ userData.name }}</span>
                </div>
                <div class="form-group d-inline-block">
                  <span class="float-left">Alamat Email</span>
                  <span class="float-right font-weight-6 text-primary">{{ userData.email }}</span>
                </div>
                <div class="form-group d-inline-block">
                  <span class="float-left">No Telp/HP</span>
                  <span class="float-right font-weight-6 text-primary">{{ userData.phone }}</span>
                </div>
                <div class="form-group d-inline-block">
                  <span class="float-left">Jenis Keanggotaan</span>
                  <span class="float-right font-weight-6 text-primary">{{ userData.user_type }}</span>
                </div>
                <div class="form-group d-inline-block mt-4 mb-1 bg-warning-light pad-10">
                  <span class="float-left font-weight-6 text-warning font-16">Harga Pemesanan</span>
                </div>
                <div class="form-group d-inline-block pl-2 pr-2 mt-4">
                  <span class="float-left">Jenis Visa</span>
                  <span class="float-right font-weight-6 text-primary">{{ dataSection.name }}</span>
                </div>

                <div class="form-group d-inline-block pl-2 pr-2">
                  <span class="float-left">Tanggal Keberangkatan</span>
                  <span class="float-right font-weight-6 text-primary">{{ VisaDate }}</span>
                </div>

                <div class="form-group d-inline-block pl-2 pr-2">
                  <span class="float-left">Jumlah Visa</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>{{ PaxVisa }}</span> PAX
                  </span>
                </div>

                <div class="form-group d-inline-block pl-2 pr-2">
                  <span class="float-left">Harga Visa/pax</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>IDR.</span>
                    {{ perPax | numeralFormat('0,0[.]00')}}
                  </span>
                </div>

                <div class="form-group d-inline-block bg-danger-light pt-3 pb-3 pl-2 pr-2">
                  <span class="float-left font-weight-6">Total Harga</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>IDR.</span>
                    {{ totalPax | numeralFormat('0,0[.]00') }}
                  </span>
                </div>

                <div class="form-group d-inline-block pt-3 pb-3 pl-2 pr-2">
                  <span
                    class="d-block w-100 mb-6 cursor-pointer bg-primary text-white text-center pad-7 border-radius-5"
                    @click.prevent="backTo()"
                  >Kembali</span>

                  <span
                    class="d-block w-100 bg-warning cursor-pointer text-white text-center pad-7 border-radius-5"
                    @click.prevent="postData()"
                  >Bayar</span>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-Success" hide-header hide-footer no-close-on-backdrop centered title>

      <div class="col-md-12 pb-6 text-center">
        <img width="150px" class="margin-auto pb-10 pt-4" src="@/assets/images/icon/check.svg" alt />
        <span class="text-primary font-weight-6 font-18">Pesanan Anda Sudah di Proses</span>
        <br />
        <span class="text-grey font-weight-5 font-16 d-block mb-15">Silahkan Lakukan Pembayaran</span>
        <span
          class="d-block width-50 bg-warning pt-2 mb-10 pb-2 font-weight-6 text-white border-radius-5 margin-auto cursor-pointer"
          @click="PaymentVisa()"
        >Bayar</span>
       
          <span @click="listtransaction()"
            class="d-block width-50 mt-10 bc-warning border-solid-2 pt-2 pb-2 text-warning font-weight-6 border-radius-5 margin-auto cursor-pointer"
          >Lihat Data Pesananan</span>
      
      </div>
    </b-modal>
     <b-modal id="modal-Payment" scrollable    hide-footer no-close-on-backdrop centered  >
    <template v-slot:modal-title>
     <span class="d-block font-18 text-primary font-weight-7">Pilih Metode Pembayaran</span>
    </template>
        <payment :dataBinding='visaNumber'></payment>
 
    </b-modal>
  </div>
</template>
<script>
// import SocialSharing from "vue-social-sharing";
import payment from "@/views/components/payment.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// import * as moment from "moment";
// import stripHtml from "string-strip-html";
export default {
  components: {
    // SocialSharing
payment,
    flatPickr
  },
  data() {
    return {
      LoginCheck: false,
      paymentURL:null,
      VisaDate: null,
      PaxVisa: null,
      perPax: null,
      dataSection: null,
      totalPax: null,
      userData: this.$store.state.userdata,
      date: new Date(),
      config: {
        mode: "single",
        minDate: "today",
        dateFormat: "D d-M-Y"
      },
      visaNumber:null,
      sort: [
        {
          id: 0,
          text: "Almaidah"
        },
        {
          id: 1,
          text: "Alabama"
        }
      ],
      sortSelect: [],

      Visa_pick: null
    };
  },
  watch: {
    Visa_pick(val) {
      this.perPax = val.price;
      this.dataSection = val;
      //  console.log(val)
    }
  },
  mounted() {
    this.$store.commit("SET_BG", false);
    this.getAPI();
   
  },

  methods: {
    listtransaction(){
        window.location.assign('https://umroh.realtravel.co.id/transaksi?hct='+this.$store.state.userdata.token)
    },
    bindingData(){
        this.$bvModal.show("modal-Payment");
    },
    postData() {
      let param = new FormData();
      param.append("visa_id", this.dataSection.id);
      param.append("pax", this.PaxVisa);
      param.append("date_depart", this.VisaDate);

      this.axios
        .post("https://api.realtravel.co.id/transaction/visa/create", param, {
          headers: {
            'accept': 'application/json', 
            'Content-Type': 'multipart/form-data, text/plain',   
            'token': this.userData.token
          }
        })
        .then(response => {
          let res = response.data 
          if(res.code == 200){
            // console.log(res.response.data[0].visa_number)
            this.visaNumber = res.response.data[0].visa_number

            this.$bvModal.show("modal-Success");
          }
         
          // console.log(res);
        });
    },
   
    changePricePax(neVal) {
      this.totalPax = neVal * this.PaxVisa;
      return neVal;
    },
    noBack(){
       
       window.location.hash="no-back-button";
            window.location.hash="Again-No-back-button";//again because google chrome don't insert first hash into history
            window.onhashchange=function(){
              window.location.hash="no-back-button";
          }
            
           
    },
    Validation() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.Visa_pick = this.Visa_pick.text;
          if (this.$store.state.userdata !== null) {
            this.LoginCheck = true;
         
          } else {
            this.$bvModal.show("modal-login");
          }
        }
      });
    },
    chesen(val){
      return val.images[0].file
      // return val[0]
    },
    getAPI() {
      this.axios.get("https://api.realtravel.co.id/visa").then(response => {
        let res = response.data.response.data;
        this.sortSelect = res;
      });
    },
    PaymentVisa(){
         this.$bvModal.hide("modal-Success");
        
         this.axios.get('https://api.realtravel.co.id/transaction/payment?type=product&invoice_number='+this.visaNumber)
         .then(response => {
           let res = response.data
           if(res.code == 200){
            //  this.paymentURL = res.response.url
            // console.log(res)
              this.$bvModal.show("modal-Payment");
           } 
         })
        //  setTimeout(() => {
        //        this.$bvModal.show("modal-Payment");
        //  }, 1000);
    },
    backTo() {
      this.LoginCheck = false;
    }
  },
  name: "visa"
};
</script>